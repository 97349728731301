import React from 'react';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import PatientsBox from '../components/PatientsBox'


export default function Patients() {
  return (
    <Layout>
        <Heading>
          Patients
        </Heading>
        <PatientsBox />
    </Layout>
  );
}
